'use strict';

var timezone = 'Asia/Hong_Kong';
var a = 1;

function getElementOffset(element) {
	var de = document.documentElement;
	// console.log('element', element);
	if (!element) return { top: 0 };
	// console.log('element.getBoundingClientRect()', element.getBoundingClientRect());
	var box = element.getBoundingClientRect();
	var top = box.top + window.pageYOffset - de.clientTop;
	var left = box.left + window.pageXOffset - de.clientLeft;
	return { top: top, left: left };
}

function eventTracking(args) {
	if (typeof ga != 'function' || typeof ga.getAll != 'function') return;
	var trackerName = ga.getAll()[0].get('name');
	ga(trackerName + '.send', 'event', {
		eventCategory: args[0] || '',
		eventAction: args[1] || '',
		eventLabel: args[2] || '',
		eventValue: args[3] || ''
	});
}

/*****/

// Declare app level module which depends on views, and components
angular.module('movie', ['ngRoute', 'LocalStorageModule']).config([
	'$routeProvider',
	'$locationProvider',
	'localStorageServiceProvider',
	function($routeProvider, $locationProvider, localStorageServiceProvider) {
		// $locationProvider.hashPrefix('!');
		$locationProvider.html5Mode(true);

		$routeProvider.when('/:language/:date', {
			template: ' ',
			controller: 'scheduleController'
		});

		// $routeProvider.when('/chinese/:date', {
		// 	template: ' ',
		// 	controller: 'scheduleController'
		// });

		localStorageServiceProvider.setPrefix('movie');

		$routeProvider.otherwise({
			redirectTo:
				'/chinese/' +
				moment()
					.tz(timezone)
					.format('YYYY-MM-DD')
		});
	}
]);
