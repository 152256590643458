angular.module('movie').factory('dataAPI', [
	'$http',
	function($http) {
		var HOST = 'https://s3-ap-southeast-1.amazonaws.com/cinema-data/public/';
		var errorresponse = function(response) {
			console.log('error response:', response);
			alert('request error');
		};
		return {
			cinemas: function(callback) {
				return $http.get(HOST + 'cinemas.json').then(function(response) {
					callback(response.data);
				}, errorresponse);
			},
			schedule: function(date, callback) {
				date = moment
					.tz(typeof date != 'undefined' ? date : '', timezone)
					.format('YYYYMMDD');
				return $http
					.get(HOST + 'schedule/' + date + '.json')
					.then(function(response) {
						callback(response.data);
					}, errorresponse);
				// return $http.get(HOST+'/schedule?date=20170712');
			}
		};
	}
]);
