angular
	.module('movie')
	.controller('mainController', [
		'$scope',
		'$filter',
		'$route',
		'$routeParams',
		'$compile',
		'dataAPI',
		'localStorageService',
		'i18nFilter',
		function(
			$scope,
			$filter,
			$route,
			$routeParams,
			$compile,
			dataAPI,
			localStorageService,
			i18nFilter
		) {
			// $scope.date = $filter('date')(new Date(), 'yyyy-MM-dd');
			$scope.supportedLanguage = ['english', 'chinese'];
			// $scope.language = 'english';
			// $scope.language = 'chinese';
			$scope.language =
				typeof $routeParams.language != 'undefined' &&
				$scope.supportedLanguage.includes($routeParams.language)
					? $routeParams.language
					: 'chinese';
			$scope.today = moment().tz(timezone);
			// $scope.date =
			// 	typeof $routeParams.date != 'undefined'
			// 		? $routeParams.date
			// 		: $scope.today.format('YYYY-MM-DD');
			$scope.date = '';
			$scope.showFilters = 1;
			$scope.showMenuTooltip = 1;
			$scope.isUpdated = false;
			$scope.loadingStatus = 2;
			$scope.sourceCinemas = {};
			$scope.sourceSchedule = {};
			$scope.sourceTimeslot = [];
			$scope.searchCinema = {
				list: {
					// default: Causeway Bay
					64: true,
					76: true,
					56: true,
					39: true,
					88: true,
					89: true
				},
				name: ''
			};
			$scope.searchMovie = {
				name: '',
				list: {}
			};
			$scope.searchSchedule = {
				version: {},
				price: {},
				time: {
					after: '',
					before: ''
				}
			};
			$scope.searchDefault = {
				cinema: angular.copy($scope.searchCinema),
				movie: angular.copy($scope.searchMovie),
				schedule: angular.copy($scope.searchSchedule)
			};
			$scope.filterOptions = {
				version: [
					'2d',
					'3d',
					'4k',
					'D-BOX',
					'atmos',
					'japanse',
					'english',
					'cantonese',
					'putonghua'
				],
				price: ['below60', '60to100', '100to160', 'above160'],
				group: [
					'broadway',
					// 'amc',
					'mcl',
					// 'gc',
					'gh',
					// 'metroplex',
					'cinemacity',
					'ua',
					'newport'
					// 'cityline',
					// 'cel',
					// 'emp',
					// 'custom',
					// 'hkac',
					// 'other'
				],
				district: [
					'mongkok',
					'tsimshatsui',
					'causewaybay',
					'kowloonbay',
					'tsuenwan',
					'tuenmun'
				]
			};
			$scope.regionList = {
				hk: {
					region: 'hk'
				},
				kln: {
					region: 'kln'
				},
				nt: {
					region: 'nt'
				}
			};
			$scope.preferences = {
				autosave: {
					searchCinema: true,
					searchMovie: false,
					searchSchedule: true
				},
				frequency: 0,
				showCinemaWithNoSession: false
			};

			/* ********** */
			$scope.showtimeList = [];
			$scope.movieList = [];
			$scope.scheduleList = [];
			$scope.scheduleListRaw = [];
			$scope.cinemaList = [];
			$scope.cinemaListRaw = [];
			$scope.totalCinema = 0;

			/* remove auto watch */
			var unregister1, unregister2, unregister3;
			unregister1 = $scope.$watch('showtimeList', function(value) {
				unregister1();
			});
			unregister2 = $scope.$watch('scheduleList', function(value) {
				unregister2();
			});
			unregister3 = $scope.$watch('cinemaList', function(value) {
				unregister3();
			});

			$scope.dateGenerator = function(day, format) {
				if (typeof format != 'undefined')
					return moment($scope.today)
						.add(day, 'd')
						.format(format);
				return moment($scope.today)
					.add(day, 'd')
					.format('MM-DD ddd');
			};

			$scope.schedulequery = function() {
				$scope.loadingStatus = 2;
				dataAPI.schedule($scope.date, function(res) {
					$scope.isUpdated = false;
					$scope.loadingStatus--;

					$scope.showtimeList = res.showtimeList.sort();
					$scope.movieList = res.movieList;
					$scope.scheduleListRaw = $scope.scheduleList = res.scheduleList;
				});
			};

			$scope.updateLanguage = function(language) {
				$scope.language =
					typeof $routeParams.language != 'undefined' &&
					$scope.supportedLanguage.includes($routeParams.language)
						? $routeParams.language
						: 'chinese';
			};

			$scope.updateSchedule = function(date) {
				$scope.showFilters = 0;
				// if (date == $scope.date) return;
				// $scope.date = date;
				$scope.date = moment.tz(date, 'YYYY-MM-DD', timezone).isValid()
					? moment.tz(date, 'YYYY-MM-DD', timezone).format('YYYY-MM-DD')
					: $scope.today.format('YYYY-MM-DD');
				$scope.schedulequery();
				eventTracking(['date', 'request', $scope.date]);
			};

			$scope.todayschedule = function() {
				$scope.updateSchedule($scope.today.format('YYYY-MM-DD'));
			};

			$scope.resetFilter = function(section) {
				if (section == 'cinema') {
					$scope.searchCinema = angular.copy($scope.searchDefault.cinema);
				} else if (section == 'movie') {
					$scope.searchMovie = angular.copy($scope.searchDefault.movie);
				} else if (section == 'schedule') {
					$scope.searchSchedule = angular.copy($scope.searchDefault.schedule);
				} else {
					// reset all sections
					$scope.searchCinema = angular.copy($scope.searchDefault.cinema);
					$scope.searchMovie = angular.copy($scope.searchDefault.movie);
					$scope.searchSchedule = angular.copy($scope.searchDefault.schedule);
				}
			};

			dataAPI.cinemas(function(res) {
				$scope.cinemaListRaw = $scope.cinemaList = res;
				$scope.totalCinema = $scope.cinemaList.length;
				$scope.isUpdated = false;
				$scope.loadingStatus--;
			});

			$scope.getMovieById = function(movieId) {
				for (var i = 0, len = $scope.movieList.length; i < len; i++) {
					if ($scope.movieList[i].movieId == movieId)
						return $scope.movieList[i];
				}
				return [];
			};

			$scope.addInfoCard = function(type, id) {
				if (type == 'cinema') {
					$scope.cinemaInfoCard = $scope.getCinemaById(id);
					var card = angular.element(
						'<cinema-card cinema-info-card="cinemaInfoCard" cinema-id="' +
							id +
							'" language="' +
							$scope.language +
							'"></cinema-card>'
					);
					$compile(card)($scope);
					// console.log('card', typeof card, card, card[0]);
					// $compile(card)($scope);
					document.getElementById('infoCardsContainer').prepend(card[0]);
					// card[0].insertAfter(
					// 	angular.element(document.getElementById('infoCardsContainer'))
					// );
					// $compile(card)($scope);
					// console.log('card', card);
				}
			};

			$scope.getTotalByGroupOrDistruct = function(value) {
				var i = 0;
				$scope.cinemaList.map(cinema => {
					if (
						cinema.type == value ||
						cinema.district == i18nFilter(value, 'english')
					) {
						i++;
					}
				});
				return i;
			};

			$scope.getTemplateUrl = function(template) {
				return $scope.language + '/' + template + '.html';
			};

			$scope.switchLanguage = function() {
				// $scope.language = $scope.language == 'chinese' ? 'english' : 'chinese';
				$scope.showFilters = false;
				/*$route.updateParams({
					language: $scope.language == 'chinese' ? 'english' : 'chinese'
				});*/
				$scope.language = $scope.language == 'chinese' ? 'english' : 'chinese';
				eventTracking(['language', 'change', $scope.language]);
				// location.href = $location.absUrl();
				location.href = '/' + $scope.language + '/' + $scope.date;
			};

			$scope.selectMovie = function(movieId) {
				$scope.searchMovie.list[movieId] = !$scope.searchMovie.list[movieId];
				if ($scope.searchMovie.list[movieId])
					eventTracking(['filter', 'searchMovie', movieId]);
			};

			$scope.getCinemaById = function(cinemaId) {
				for (var i = 0; i < $scope.totalCinema; i++) {
					if ($scope.cinemaList[i].cinemaId == cinemaId)
						return $scope.cinemaList[i];
				}
			};

			$scope.enableAutosave = function(autosave) {
				// searchCinema: true,
				// searchMovie: false,
				// searchSchedule: true
				if (autosave.searchCinema) {
					var searchCinema = localStorageService.get('searchCinema');

					if (searchCinema) $scope.searchCinema = angular.copy(searchCinema);
					else localStorageService.set('searchCinema', $scope.searchCinema);

					$scope.disableAutosaveSearchCinema = localStorageService.bind(
						$scope,
						'searchCinema'
					);
				} else {
					if (typeof $scope.disableAutosaveSearchCinema == 'function')
						$scope.disableAutosaveSearchCinema();
					eventTracking(['setting', 'change', 'autosaveSearchCinema', 0]);
				}

				if (autosave.searchMovie) {
					var searchMovie = localStorageService.get('searchMovie');

					if (searchMovie) $scope.searchMovie = angular.copy(searchMovie);
					else localStorageService.set('searchMovie', $scope.searchMovie);

					$scope.disableAutosaveSearchMovie = localStorageService.bind(
						$scope,
						'searchMovie'
					);
					eventTracking(['setting', 'change', 'autosaveSearchMovie', 1]);
				} else {
					if (typeof $scope.disableAutosaveSearchMovie == 'function')
						$scope.disableAutosaveSearchMovie();
				}

				if (autosave.searchSchedule) {
					var searchSchedule = localStorageService.get('searchSchedule');

					if (searchSchedule)
						$scope.searchSchedule = angular.copy(searchSchedule);
					else localStorageService.set('searchSchedule', $scope.searchSchedule);

					$scope.disableAutosaveSearchSchedule = localStorageService.bind(
						$scope,
						'searchSchedule'
					);
				} else {
					if (typeof $scope.disableAutosaveSearchSchedule == 'function')
						$scope.disableAutosaveSearchSchedule();
					eventTracking(['setting', 'change', 'autosaveSearchSchedule', 0]);
				}

				/*if (enable) {
					$scope.preferences.autosave = true;

					var searchCinema = localStorageService.get('searchCinema');
					if (searchCinema) $scope.searchCinema = angular.copy(searchCinema);
					$scope.disableAutosaveSearchCinema = localStorageService.bind(
						$scope,
						'searchCinema'
					);

					var searchMovie = localStorageService.get('searchMovie');
					if (searchMovie) $scope.searchMovie = angular.copy(searchMovie);
					$scope.disableAutosaveSearchMovie = localStorageService.bind(
						$scope,
						'searchMovie'
					);

					var searchSchedule = localStorageService.get('searchSchedule');
					if (searchSchedule)
						$scope.searchSchedule = angular.copy(searchSchedule);
					$scope.disableAutosaveSearchSchedule = localStorageService.bind(
						$scope,
						'searchSchedule'
					);
				} else {
					$scope.preferences.autosave = false;
					if (typeof $scope.disableAutosaveSearchCinema == 'function')
						$scope.disableAutosaveSearchCinema();
					if (typeof $scope.disableAutosaveSearchMovie == 'function')
						$scope.disableAutosaveSearchMovie();
					if (typeof $scope.disableAutosaveSearchSchedule == 'function')
						$scope.disableAutosaveSearchSchedule();
				}*/
			};

			// autosave preferences
			if (localStorageService.isSupported) {
				var preferences = localStorageService.get('preferences');
				if (preferences) $scope.preferences = angular.copy(preferences);
				else localStorageService.set('preferences', $scope.preferences);
				localStorageService.bind($scope, 'preferences');

				// if ($scope.preferences.autosave) {
				// 	$scope.enableAutosave(true);
				// }

				$scope.preferences.frequency += 1;
				if ($scope.preferences.frequency > 5) {
					$scope.showMenuTooltip = 0;
				}

				$scope.enableAutosave($scope.preferences.autosave);

				$scope.$watch(
					'preferences',
					function(value) {
						$scope.enableAutosave($scope.preferences.autosave);
					},
					true
				);
			}

			// init
			// $scope.todayschedule();
			// $scope.updateSchedule($scope.date);
		}
	])
	.controller('scheduleController', [
		'$scope',
		'$routeParams',
		'dataAPI',
		function($scope, $routeParams, dataAPI) {
			// $scope.date =
			// 	typeof $routeParams.date != 'undefined'
			// 		? $routeParams.date
			// 		: $scope.today.format('YYYY-MM-DD');
			if ($routeParams.date != $scope.date)
				$scope.updateSchedule($routeParams.date);
			if ($routeParams.language != $scope.language)
				$scope.updateLanguage($routeParams.language);
		}
	]);
