/*
TO-DO:
1. apply click event on menu for universal event racking
2. cinema details model
*/

var ticketingURLPrefix = {
	broadway: 'https://www.cinema.com.hk/tc/ticketing/seatplan/{{siteId}}',
	amc: 'https://www.amccinemas.com.hk/ticketing/movieSeat/{{siteId}}',
	mcl: 'https://www.mclcinema.com/MCLSelectSeat.aspx?visLang=1&{{siteId}}',
	gc: 'https://www.mclcinema.com/MCLSelectSeat.aspx?visLang=1&{{siteId}}',
	ua: 'https://www.uacinemas.com.hk/chi/ticketing/overview?{{siteId}}',
	cinemacity: 'https://www.cinemacity.com.hk/tc/ticketing/seatplan/{{siteId}}',
	// gh: 'https://www.goldenharvest.com/film/seatPreview?cinema_id=8&film_id=274&film_show_id=36864', // url provided
	metroplex: 'https://www.metroplex.com.hk/zh_tw/order/seatplan/{{siteId}}',
	cityline: 'https://www.cityline.com/cinema/{{siteId}}/buy.do',
	newport: 'https://www.theatre.com.hk/tc/ticketing/seatplan/{{siteId}}'
};

var otherCinemas = {
	65: 'https://www.facebook.com/LuxTheatre', // 寶石
	41: 'https://www.theatre.com.hk/tc/cinema/dynasty_theatre?page=cinemaSchedule', // 豪華
	87: 'http://www.hkfaa.net/cinema', // 饒館新一天院線
	67: 'https://www.facebook.com/L-Cinema-Shau-Kei-Wan-463814693803369/', // 筲箕灣L Cinema
	54: 'https://www.facebook.com/maonshancinema/', // 馬鞍山戲院
	37: 'http://www.cel-cinemas.com/movie_showing.jsp' // 巴倫紐戲院@華懋
};

angular
	.module('movie')
	.directive('scheduleTable', [
		'i18nFilter',
		'localStorageService',
		function(i18nFilter, localStorageService) {
			var tableGenerator = (scope, language) => {
				var showtimeList = scope.showtimeList,
					cinemaList = scope.cinemaList,
					scheduleList = scope.scheduleList,
					movieList = scope.movieList;

				var searchMovie = scope.searchMovie,
					searchMovieIdList = [],
					searchTimeAfter = scope.searchSchedule.time.after,
					searchTimeBefore = scope.searchSchedule.time.before,
					searchVersion = scope.searchSchedule.version,
					searchPrice = scope.searchSchedule.price,
					searchCinema = scope.searchCinema,
					searchCinemaList = [];

				var cinemaIdList = [];

				/* BEGIN data filter */

				/***** (deprecated) *****/
				// cinema filters
				/*if (searchCinema.name) {
					var re = new RegExp(searchCinema.name, 'i');
					cinemaList = cinemaList.filter(
						cinema => cinema.name.match(re) || cinema.chiName.match(re)
					);
				}*/

				Object.keys(searchCinema.list).forEach(cinemaId => {
					searchCinema.list[cinemaId] &&
						searchCinemaList.push(parseInt(cinemaId));
				});

				// when no cinema selected, use default setting to avoid overloading
				if (!searchCinemaList.length) {
					searchCinemaList = Object.keys(scope.searchDefault.cinema.list).map(
						cinemaId => parseInt(cinemaId, 10)
					);
				}

				if (searchCinemaList.length)
					cinemaList = cinemaList.filter(cinema =>
						searchCinemaList.includes(cinema.cinemaId)
					);
				cinemaIdList = cinemaList.map(cinema => cinema.cinemaId);
				cinemaIdList = [...new Set([...cinemaIdList])];

				// movie filters

				// remove schedules not included in the selected cinemas
				scheduleList = scheduleList.filter(schedule =>
					cinemaIdList.includes(schedule.cinemaId)
				);

				if (Object.keys(searchMovie.list).length) {
					Object.keys(searchMovie.list).forEach(movieId => {
						if (!searchMovie.list[movieId]) return;
						searchMovieIdList.push(parseInt(movieId));
					});
					if (searchMovieIdList.length) {
						scheduleList = scheduleList.filter(schedule => {
							return searchMovieIdList.includes(schedule.movieId);
						});
					}
				}

				if (Object.keys(searchVersion).length) {
					Object.keys(searchVersion).forEach(option => {
						if (!searchVersion[option]) delete searchVersion[option];
					});
					searchVersion = Object.keys(searchVersion);
					scheduleList = scheduleList.filter(schedule =>
						searchVersion.find(version => schedule.version.includes(version))
					);
				}

				if (Object.keys(searchPrice).length) {
					var priceChecker = price => {
						price = parseInt(price);
						switch (true) {
							case price <= 60:
								return 'below60';
							case price > 60 && price <= 100:
								return '60to100';
							case price > 100 && price <= 160:
								return '100to160';
							case price > 160:
								return 'above160';
							default:
								// undefined
								return false;
						}
					};
					Object.keys(searchPrice).forEach(option => {
						if (!searchPrice[option]) delete searchPrice[option];
					});
					searchPrice = Object.keys(searchPrice);
					scheduleList = scheduleList.filter(schedule => {
						var priceType = priceChecker(schedule.ticketPrice);
						if (!priceType) return true;
						return searchPrice.includes(priceType);
					});
				}

				showtimeList = scheduleList.map(schedule => schedule.unixShowtime);
				showtimeList = [...new Set([...showtimeList])].sort();

				if (searchTimeAfter) {
					showtimeList = showtimeList.filter(
						showtime =>
							moment.tz(showtime, 'X', timezone).hours() >= searchTimeAfter // moment.unix(showtime).hours() >= searchTimeAfter
					);
				}

				if (searchTimeBefore) {
					showtimeList = showtimeList.filter(
						showtime =>
							moment.tz(showtime, 'X', timezone).hours() <= searchTimeBefore // moment.unix(showtime).hours() <= searchTimeBefore
					);
				}

				// sort by district
				cinemaList.sort(function(a, b) {
					return a.district.localeCompare(b.district);
					// return parseFloat(a.district) - parseFloat(a.district);
				});

				// remove cinemas without any session
				if (!scope.preferences.showCinemaWithNoSession) {
					cinemaIdList = scheduleList.map(schedule => schedule.cinemaId);
					cinemaList = cinemaList.filter(cinema =>
						cinemaIdList.includes(cinema.cinemaId)
					);
				}
				/* END data filter */

				var mainGeneratorUI = () => {
					var index, index2, index3, length3, movies;
					var length = showtimeList.length;
					var length2 = cinemaList.length;
					var content = '';
					var ticketingURL = '';

					for (index = 0; index < length; index++) {
						var showtime = moment.tz(showtimeList[index], 'X', timezone);
						content +=
							'<dt hour="' +
							showtime.hour() +
							'">' +
							showtime.format('hh:mm A') +
							'</dt>';

						for (index2 = 0; index2 < length2; index2++) {
							content += '<dd>';
							movies = getMoviesByUnixShowtimeAndCinemaId(
								showtimeList[index],
								cinemaList[index2].cinemaId
							);
							length3 = movies.length;

							for (index3 = 0; index3 < length3; index3++) {
								ticketingURL = getTicketingURL(
									movies[index3],
									cinemaList[index2].type
								);
								content +=
									'<showing' + (!ticketingURL ? ' class="nourl"' : '') + '>';
								if (ticketingURL)
									content += '<a href="' + ticketingURL + '" target="_blank">';
								content +=
									typeof movies[index3].ticketPrice != 'undefined' &&
									movies[index3].ticketPrice
										? '<price>$' + movies[index3].ticketPrice + '</price>'
										: '';
								if (typeof movies[index3].version != 'undefined') {
									content += movies[index3].version.includes('3d')
										? '<version>' + i18nFilter('3d', language) + '</version>'
										: '';
									content += movies[index3].version.includes('4k')
										? '<version>' + i18nFilter('4k', language) + '</version>'
										: '';
									content += movies[index3].version.includes('D-BOX')
										? '<version>' + i18nFilter('D-BOX', language) + '</version>'
										: '';
									content += movies[index3].version.includes('imax')
										? '<version>' + i18nFilter('imax', language) + '</version>'
										: '';
									content += movies[index3].version.includes('atmos')
										? '<version>' + i18nFilter('atmos', language) + '</version>'
										: '';
								}
								if (language == 'chinese') {
									content += '<span>' + movies[index3].chiName + '</span>';
								} else {
									// english
									content += '<span>' + movies[index3].name + '</span>';
								}
								if (ticketingURL) content += '</a>';
								content += '</showing>';
							}

							content += '</dd>';
						}
					}

					return content;
				};

				var getTicketingURL = (schedule, type) => {
					// url || siteId + (cinema)type
					if (typeof schedule.url != 'undefined') return schedule.url;
					if (
						typeof type != 'undefined' &&
						typeof ticketingURLPrefix[type] != 'undefined' &&
						typeof schedule.siteId != 'undefined'
					)
						return ticketingURLPrefix[type].replace(
							/{{siteId}}/,
							schedule.siteId
						);
					return false;
				};

				var getMoviesByUnixShowtimeAndCinemaId = (unixShowtime, cinemaId) => {
					var movieFilter = [];
					scheduleList = scheduleList.filter((value, index) => {
						if (
							value.unixShowtime == unixShowtime &&
							value.cinemaId == cinemaId
						) {
							// if (typeof value.url == 'undefined') console.log(value);
							// else console.log('yes url');
							movieFilter.push(value);
							return false;
						}
						return true;
					});

					var movies = movieFilter.map(value =>
						Object.assign(value, getMovieById(value.movieId))
					);
					return movies;
				};

				var getMovieById = movieId => {
					for (var i = 0, len = movieList.length; i < len; i++) {
						if (movieList[i].movieId == movieId) return movieList[i];
					}
					return [];
				};

				var headerGeneratorUI = () => {
					var index;
					var html = '';
					var length = cinemaList.length;
					if (language == 'chinese') {
						for (index = 0; index < length; index++) {
							html +=
								'<dd cinema-id="' +
								cinemaList[index].cinemaId +
								'"><span>' +
								cinemaList[index].chiShortname +
								'</span></dd>';
						}
					} else {
						// english
						for (index = 0; index < length; index++) {
							html +=
								'<dd cinema-id="' +
								cinemaList[index].cinemaId +
								'"><span>' +
								cinemaList[index].shortname +
								'</span></dd>';
						}
					}
					return html;
				};

				var scheduleTableHTML = `
        <dl class="header">
          ${headerGeneratorUI()}
        </dl>
        <dl class="main">
          ${mainGeneratorUI()}
        </dl>`;

				return scheduleTableHTML;
			};

			return {
				restrict: 'E',
				link: (scope, element, attr) => {
					// var language = scope.supportedLanguage.includes(attr.language)
					// 	? attr.language
					// 	: 'english';
					// var language = scope.language;

					var updateTable = scope => {
						element.html(tableGenerator(scope, scope.language));

						// auto scope to specific time if browsing todays schedule
						if (scope.date == scope.today.format('YYYY-MM-DD')) {
							var hour = scope.today.hour();

							// smooth scrolling
							/*document.querySelector('dl[class="main"]').scroll({
                top: getElementOffset(document.querySelector('dt[hour="'+scope.today.hour()+'"]')).top - 100,
                behavior: 'smooth'
              });*/
							document.querySelector('dl[class="main"]').scrollTop =
								getElementOffset(
									document.querySelector(
										'dt[hour="' + scope.today.hour() + '"]'
									)
								).top - 100;
						}

						// scroll sync
						var elements = element.find('dl');
						var scrollLeft = 0;
						elements.on('scroll', function(e) {
							if (e.isTrigger) {
								e.target.scrollLeft = scrollLeft;
							} else {
								scrollLeft = e.target.scrollLeft;
								angular.forEach(elements, function(element, key) {
									if (!element.isEqualNode(e.target)) {
										element.scrollLeft = scrollLeft;
									}
								});
							}
						});

						var ddelements = element.find('dd');
						ddelements.on('click', function(e) {
							scope.addInfoCard('cinema', this.getAttribute('cinema-id'));
						});
					};

					scope.$watch('showtimeList', function(value) {
						updateTable(scope);
					});

					scope.$watch(
						'searchCinema',
						function(value) {
							updateTable(scope);
						},
						true
					);

					scope.$watch(
						'searchMovie',
						function(value) {
							updateTable(scope);
						},
						true
					);

					scope.$watch(
						'searchSchedule',
						function(value) {
							updateTable(scope);
						},
						true
					);

					scope.$watch(
						'preferences.showCinemaWithNoSession',
						function(value) {
							updateTable(scope);
						},
						true
					);
				}
			};
		}
	])

	.directive('cinemaFilter', function() {
		function link(scope, element, attrs) {
			var regions = element.find('legend').find('a');
			regions.on('click', function(e) {
				e.preventDefault();
				var isSelectedAll = this.getAttribute('is-selected-all') || 'false';
				var region = this.getAttribute('region');
				if (isSelectedAll == 'false') {
					this.setAttribute('is-selected-all', 'true');
					scope.cinemaList.map(cinema => {
						if (cinema.region == region)
							scope.searchCinema.list[cinema.cinemaId] = true;
					});
					eventTracking(['filter', 'searchCinemaRegion', region]);
				} else {
					this.setAttribute('is-selected-all', 'false');
					scope.cinemaList.map(cinema => {
						if (cinema.region == region)
							delete scope.searchCinema.list[cinema.cinemaId];
					});
				}
				scope.$apply();
			});

			var groups = element.find('group-list');
			groups.on('click', function(e) {
				e.preventDefault();
				if (e.target.tagName.toLowerCase() != 'tag') return;
				var self = e.target;
				var isSelectedAll = self.getAttribute('is-selected-all') || 'false';
				var group = self.getAttribute('group'),
					district = self.getAttribute('district');
				if (isSelectedAll == 'false') {
					scope.cinemaList.map(cinema => {
						if (cinema.type == group || cinema.district == district) {
							scope.searchCinema.list[cinema.cinemaId] = true;
						} else {
							scope.searchCinema.list[cinema.cinemaId] = false;
						}
					});
					if (group != 'deselectAll') {
						self.setAttribute('is-selected-all', 'true');
					}
					eventTracking(['filter', 'searchCinemaType', group]);
				} else {
					self.setAttribute('is-selected-all', 'false');
					scope.cinemaList.map(cinema => {
						if (cinema.type == group || cinema.district == district) {
							delete scope.searchCinema.list[cinema.cinemaId];
							// scope.searchCinema.list[cinema.cinemaId] = false;
						}
					});
				}
				scope.$apply();
			});

			// track cinema filter
			var fieldsets = element.find('fieldset');
			fieldsets.on('click', function(e) {
				// e.preventDefault();
				if (e.target.tagName.toLowerCase() != 'input') return;
				var self = e.target;
				if (e.target.checked == true) {
					eventTracking([
						'filter',
						'searchCinema',
						self.getAttribute('cinema-id')
					]);
				}
			});
		}

		return {
			restrict: 'E',
			templateUrl: function(elem, attr) {
				return attr.language + '/cinema-filter.html';
			},
			// templateUrl: scope.language + '/cinema-filter.html',
			// template:
			// 	'<section xx ng-include="getTemplateUrl(\'cinema-filter\')"></section>',
			transclude: true,
			link: link
		};
	})

	.directive('movieFilter', function() {
		function link(scope, element, attrs) {
			var lazylazyload = function(element) {
				element.parent().on('scroll', function() {
					var thumbnails = element.find('img');
					angular.forEach(thumbnails, function(element, key) {
						var thumbnail = angular.element(element);
						if (!thumbnail.attr('thumb-src')) return;
						thumbnail.attr('src', thumbnail.attr('thumb-src'));
					});
					element.parent().off('scroll');
				});

				// load the thumbnails immediately if scrollTop != 0
				if (element.parent()[0].scrollTop != 0) {
					setTimeout(function() {
						element.parent().triggerHandler('scroll');
					}, 100);
				}
			};

			scope.$watch(
				'movieList',
				function(value) {
					lazylazyload(element);
				},
				true
			);

			var groups = element.find('duration-list').find('tag');
			groups.on('click', function(e) {
				e.preventDefault();
				var isSelectedAll = this.getAttribute('is-selected-all') || 'false';
				var option = this.getAttribute('option');
				if (isSelectedAll == 'false') {
					this.setAttribute('is-selected-all', 'true');
					if (option == 'below90') {
						scope.movieList.map(movie => {
							if (movie.duration < 90)
								scope.searchMovie.list[movie.movieId] = true;
						});
					} else if (option == '90to120') {
						scope.movieList.map(movie => {
							if (movie.duration >= 90 && movie.duration <= 120)
								scope.searchMovie.list[movie.movieId] = true;
						});
					} else if (option == 'above120') {
						scope.movieList.map(movie => {
							if (movie.duration > 120)
								scope.searchMovie.list[movie.movieId] = true;
						});
					}
				} else {
					this.setAttribute('is-selected-all', 'false');
					if (option == 'below90') {
						scope.movieList.map(movie => {
							if (movie.duration < 90)
								delete scope.searchMovie.list[movie.movieId];
						});
					} else if (option == '90to120') {
						scope.movieList.map(movie => {
							if (movie.duration >= 90 && movie.duration <= 120)
								delete scope.searchMovie.list[movie.movieId];
						});
					} else if (option == 'above120') {
						scope.movieList.map(movie => {
							if (movie.duration > 120)
								delete scope.searchMovie.list[movie.movieId];
						});
					}
				}
				scope.$apply();
			});
		}

		return {
			restrict: 'E',
			templateUrl: function(elem, attr) {
				return attr.language + '/movie-filter.html';
			},
			// templateUrl: 'english/movie-filter.html',
			// template:
			// 	'<section xx ng-include="getTemplateUrl(\'movie-filter\')"></section>',
			transclude: true,
			link: link
		};
	})

	.directive('miscFilter', function() {
		return {
			restrict: 'E',
			// templateUrl: 'english/misc-filter.html',
			templateUrl: function(elem, attr) {
				return attr.language + '/misc-filter.html';
			},
			// template:
			// '<section xx ng-include="getTemplateUrl(\'misc-filter\')"></section>',
			transclude: true
			// link: link
		};
	})
	.directive('cinemaCard', function() {
		return {
			restrict: 'E',
			templateUrl: function(elem, attr) {
				return attr.language + '/cinema-card.html';
			},
			transclude: true,
			scope: {
				cinemaInfoCard: '<'
			},
			link: function link(scope, element, attrs) {
				scope.cinema = angular.copy(scope.cinemaInfoCard);
				element.find('close').on('click', function(e) {
					// e.preventDefault();
					this.parentNode.parentNode.remove();
				});
			}
		};
	});
