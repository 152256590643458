angular
	.module('movie')
	.filter('i18n', function() {
		var i18n = {
			english: {
				'2d': '2D',
				'3d': '3D',
				'4d': '4DX',
				'4k': '4K',
				imax: 'IMAX',
				'D-BOX': 'D-BOX',
				atmos: 'Atmos',
				japanse: 'Japanese',
				english: 'English',
				cantonese: 'Cantonese',
				putonghua: 'Putonghua',
				below60: '< $60',
				'60to100': '$60 ~ $100',
				'100to160': '$100 ~ $160',
				above160: '> $160',
				broadway: 'Broadway',
				amc: 'AMC',
				mcl: 'MCL',
				gc: 'Grand Cinema',
				gh: 'Golden Harvest',
				metroplex: 'Metroplex',
				cinemacity: 'Cinemacity',
				ua: 'UA',
				newport: 'Newport',
				cityline: 'Ciyline',
				cel: 'CEL',
				emp: 'EMP',
				// custom: 'Custom',
				hkac: 'Arts Centre',
				other: 'Other',
				autosave: 'Autosave filters',
				autosaveSearchCinema: 'Autosave cinema filter',
				autosaveSearchMovie: 'Autosave movie filter',
				autosaveSearchSchedule: 'Autosave misc filters',
				mongkok: 'Mong Kok',
				tsimshatsui: 'Tsim Sha Tsui',
				causewaybay: 'Causeway Bay',
				kowloonbay: 'Kowloon Bay',
				tsuenwan: 'Tsuen Wan',
				tuenmun: 'Tuen Mun',
				nt: 'New Territories',
				kln: 'Kowloon',
				hk: 'Hong Kong Island'
			},
			chinese: {
				'2d': '2D',
				'3d': '3D',
				'4d': '4DX',
				'4k': '4K',
				imax: 'IMAX',
				'D-BOX': 'D-BOX',
				atmos: '全景聲',
				japanse: '日語',
				english: '英語',
				cantonese: '廣東話',
				putonghua: '普通話',
				below60: '< $60',
				'60to100': '$60 ~ $100',
				'100to160': '$100 ~ $160',
				above160: '> $160',
				broadway: '百老匯',
				amc: 'AMC',
				mcl: 'MCL',
				gc: 'Grand Cinema',
				gh: '嘉禾',
				metroplex: '星影匯',
				cinemacity: 'Cinema City',
				ua: 'UA',
				newport: '新寶',
				cityline: '影藝',
				cel: '華懋',
				emp: '英皇',
				// custom: '',
				hkac: '藝術中心',
				other: '其他',
				autosave: '自動儲存篩選',
				autosaveSearchCinema: '自動儲存戲院篩選',
				autosaveSearchMovie: '自動儲存電影篩選',
				autosaveSearchSchedule: '自動儲存其他篩選',
				mongkok: '旺角',
				tsimshatsui: '尖沙咀',
				causewaybay: '銅鑼灣',
				kowloonbay: '九龍灣',
				tsuenwan: '荃灣',
				tuenmun: '屯門',
				nt: '新界',
				kln: '九龍',
				hk: '香港島'
			}
		};

		return function(word, language) {
			// console.log(i18n, language, word);
			// language = language == 'englishUI' ? 'english' : language; // TO-DO: remove it
			// console.log('language', language);
			return i18n[language][word];
		};
	})
	.filter('number2hour', function() {
		return function(number) {
			number = parseInt(number);
			return !number
				? '12 AM'
				: number == 12
					? '12 PM'
					: number < 12 ? number + ' AM' : number - 12 + ' PM';
			// return number > 12 ? (number - 12)+' PM';
		};
	});
